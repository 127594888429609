;(function($){

  Number.prototype.formatMoney = function(c, d, t){
    var n = this, 
    c = isNaN(c = Math.abs(c)) ? 2 : c, 
    d = d == undefined ? "." : d, 
    t = t == undefined ? "," : t, 
    s = n < 0 ? "-" : "", 
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  };



  Drupal.behaviors.helper_full_cart = {
    attach: function (context, settings) {
      var $form = $('#commerce-helper-cart-full-form', context);

      if($form.length){
        var $fields = $('.form-type-textfield input', $form);
        var $total = $('.total span', $form);
        var $totalWithVat = $('.total_with_vat', $form);
        var vatSuffix = $('.product_info .vat').first().clone().children().remove().end().text();

        function updateTotal (){
          var total = 0;
          $fields.each(function(){
            var raw_price = $(this).closest('.product').find('.price span:first').text().replace(/\D/g,'');
            var qty = $(this).val();
            total += parseInt(raw_price)*qty;
          });
          $total.text( (total/100).formatMoney(2, ',', ' ') );
          $totalWithVat.hide();
          if(total > 0) {
            $totalWithVat.show().text( (total/100*1.21).formatMoney(2, ',', ' ') + vatSuffix);
          }
        }

        $('.attribute_checkbox', $form).change(function(){
          var $qty = $(this).parent().find('.quantity_wanted_p');

          if($(this).is(':checked')){
            $qty.show().find('input').val(1);
          } else {
            $qty.hide().find('input').val(0);
          }

          updateTotal();
        });

        $fields.change(updateTotal);
      }

      var cart_block = new HoverWatcher('#header .cart_block');
      var shopping_cart = new HoverWatcher('#header .cartBox');

      var is_touch_enabled = false;
      if ('ontouchstart' in document.documentElement) {
          is_touch_enabled = true;
      }
      if (!is_touch_enabled) {
        $('#header .shopping_cart a:first').hover(function() {
          if (parseInt($('.ajax_cart_quantity').html()) > 0)
            $('#header .cart_block').stop(true, true).fadeIn(450).prev().addClass('active');
        }, function() {
          setTimeout(function() {
            if (!shopping_cart.isHoveringOver() && !cart_block.isHoveringOver())
              $('#header .cart_block').stop(true, true).fadeOut(450).prev().removeClass('active');
          }, 200);
        });
      }

      $('#header .cart_block').hover(function() {
          setTimeout(function() {
              if (!shopping_cart.isHoveringOver()) {
                  $('#header .cart_block').stop(true, true).fadeOut(450).prev().removeClass('active');
              }
          }, 200);
      });



      $('.product_quantity_up').once(function(){

        $(this).click(function(e) {
            e.preventDefault();
            var $form = $(this).closest('form');
            var $input = $('input[name="' + $(this).data('field-qty') + '"]', $form);
            var currentVal = parseInt($input.val());

            if (!isNaN(currentVal)) {
                $input.val(currentVal + 1).trigger('keyup');
            } else {
                $input.val(quantityAvailableT);
            }
            $input.change();
        });
      })

      $('.product_quantity_down').once(function(){
        $(this).click(function(e) {
            e.preventDefault();
            var $form = $(this).closest('form');
            var $input = $('input[name="' + $(this).data('field-qty') + '"]', $form);
            var currentVal = parseInt($input.val());
            if (!isNaN(currentVal) && currentVal > 1) {
                $input.val(currentVal - 1).trigger('keyup');
            } else {
                $input.val($input.hasClass('zero-allowed') ? 0 : 1);
                var $product = $(this).closest('.product');
                $product.length && $product.find('.attribute_checkbox').click();
            }
            $input.change();
        });
      });



    }
  };

  if(Drupal.ajax){
    Drupal.ajax.prototype.commands.cart_update = function(ajax, response, status) {
      $('#layer_cart, .layer_cart_overlay').remove();
      var n = parseInt($(window).scrollTop()+20) + 'px';
      $('body').append(response.cart_layer);
      $('.layer_cart_overlay').css('width', '100%');
      $('.layer_cart_overlay').css('height', '100%');
      $('.layer_cart_overlay').show();
      $('#layer_cart').css({ 
          'top': n
      }).fadeIn('fast');

      $('#commerce-helper-cart-full-form :checked').click();
    };
  }

})(jQuery);
