;
var CUSTOMIZE_TEXTFIELD = 1;
var ajax_allowed = true;
var ajaxsearch = true;
var blocking_popup = '0';
var comparator_max_item = 2;
var comparedProductsIds = [];
var contentOnly = false;

var currencyBlank = 0;
var currencyFormat = 1;
var currencyRate = 1;
var displayList = false;
var email_create = false;
var generated_date = 1527760360;
var hasDeliveryAddress = false;
var highDPI = false;

var homeslider_loop = 0;
var homeslider_pause = 3000;
var homeslider_speed = 500;
var homeslider_width = 10000;

var idSelectedCountry = false;
var idSelectedCountryInvoice = false;
var idSelectedState = false;
var idSelectedStateInvoice = false;
var id_lang = 3;
var instantsearch = true;
var isGuest = 0;
var isLogged = 0;
var isMobile = false;
var is_logged = false;
var nbItemsPerLine = 3;
var nbItemsPerLineMobile = 2;
var nbItemsPerLineTablet = 2;
var popup_status = false;
var priceDisplayMethod = 1;
var priceDisplayPrecision = 2;
var quickView = true;
var roundMode = 2;
var tmnewsletter_status = '2';
var tmolarkchat_status = '2';

var TM_PLG_TYPE = 'rollover';
var TM_PLG_ROLLOVER_ANIMATION = 'horizontal_slide';
var TM_PLG_DISPLAY_ITEMS = 20;
var TM_PLG_INFINITE = 1;
var TM_PLG_USE_PAGER = 1;
var TM_PLG_USE_CONTROLS = 1;
var TM_PLG_USE_THUMBNAILS = 1;
var TM_PLG_USE_CAROUSEL = 1;
var TM_PLG_USE_CONTROLS_THUMBNAILS = 1;
var TM_PLG_USE_PAGER_THUMBNAILS = 1;
var TM_PLG_CENTERING_THUMBNAILS = false;
var TM_PLG_POSITION_THUMBNAILS = 'horizontal';
var TM_PLG_NB_THUMBNAILS = 3;
var TM_PLG_NB_SCROLL_THUMBNAILS = 1;

//=require vendor/jquery.cookie.min.js
//=require components/general.js
//=require components/user.js
//=require components/cart.js