;(function($){

  user = {
    sidebar: function(){

      this.elem = $('.tmha-sidebar-right');

      this.toggle = function() {
          this.elem.toggleClass('active');
      };
      this.hide = function() {
          this.elem.removeClass('active');
      };

    },
  };

  $(document).ready(function(){
    var sidebar = new user.sidebar();
    
    $('.tm_header_user_info').on('click', function() {
      sidebar.toggle();
    });

    $(document).on('click', function(event) {
      if ($(event.target).closest('.tmha-sidebar-right').length === 0 || event.target.classList.contains('tmha-close-btn')) {
        sidebar.hide();
      }
    });

  });

  Drupal.behaviors.userSidebar = {
    attach: function(){
      $('.login-content a.create').on('click', function(e) {
          e.preventDefault();
          $(this).closest('.header-login-content').find('.login-content').addClass('hidden');
          $(this).closest('.header-login-content').find('.create-account-content').removeClass('hidden');
      });
      $('.create-account-content a.signin, .forgot-password-content a.signin').on('click', function(e) {
          e.preventDefault();
          $(this).closest('.header-login-content').find('.login-content').removeClass('hidden');
          $(this).closest('.header-login-content').find('.create-account-content').addClass('hidden');
          $(this).closest('.header-login-content').find('.forgot-password-content').addClass('hidden');
      });
      $('.login-content a.forgot-password').on('click', function(e) {
          e.preventDefault();
          $(this).closest('.header-login-content').find('.login-content').addClass('hidden');
          $(this).closest('.header-login-content').find('.forgot-password-content').removeClass('hidden');
      });
      $('.login-content, .create-account-content, .forgot-password-content, #user-login, #user-profile-form, #user-pass-reset').find('.btn').click(function(e){
        e.preventDefault();
        $(this).next().click();
      });

      $('.password-link').click(function(){
        $('.tmha-sidebar-right').addClass('active');
        $('.forgot-password').click();

        var name = '';
        if($('#user-login [name="name"]').val()){
          name = $('#user-login [name="name"]').val();
        }
        $('#user-pass [name="name"]').val(name);
        return false;
      });

      $('.login-link').click(function(){
        $('.tmha-sidebar-right').addClass('active');
        return false;
      });

    }
  }

})(jQuery)