;(function($){
  Drupal.behaviors.checkout = {
    attach: function(){

      var $dialogWrapper=$("#confirmation-dialog");if(!$.cookie("confirme")){$dialogWrapper.fadeIn(300)}$("button",$dialogWrapper).click(function(){$dialogWrapper.fadeOut(300);$.cookie("confirme",true)});
      
      $('#commerce-checkout-form-checkout select').once(function(){
        $(this).uniform();
      });

      function setMinHeight() {
        var maxHeight = 0;
        var start = 0;
        var items = $('.product-container');

        var columns = $(window).width() > 991 
            ? 3 
            : $(window).width() < 480 ? 1 : 2;

        var titleSelector = 'h5[itemprop=name]';

        items.find(titleSelector).height('auto');

        items.each(function(i, e) {
            
            var height = $(titleSelector, this).height();
            maxHeight = height > maxHeight ? height : maxHeight;
            if((i+1) % columns === 0 || items.length === i+1) {
                items.slice(start, i+1).find(titleSelector).height(maxHeight);
                start = i + 1;
                maxHeight = 0;
            }
        });
      }

      setMinHeight();
      $(window).resize(setMinHeight);
    }
  }
})(jQuery);